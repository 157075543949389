/*
 * Colors
 */

$white: #FFFFFF;
$black: #000000;

$gray: #222229;

$beige: #F9E1D5;
$beige-2: #FACFBC;
$beige-3: #FFF7ED;
