.sidemenu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 80rem;
  max-width: 100vw;
  margin: 0 auto;
  z-index: 4;

  &__container {
    position: absolute;
    top: 0;
    transition: transform 0.25s ease-out, opacity 0.25s;
    opacity: 0;
    background: $sidenav-bg;

    &:before {
      content: ' ';
      position: absolute;
      background: $sidenav-bg;
      top: 0;
      height: 100%;
      width: 50vw;
    }


    .sidemenu.is-right & {
      right: 0;
      transform: translateX(200%);

      &:before {
        right: -50vw;
      }
    }

    .sidemenu.is-left & {
      left: 0;
      transform: translateX(-200%);

      &:before {
        left: -50vw;
      }
    }

    .sidemenu.is-open & {
      transform: translateX(0);
      opacity: 1;
    }
  }
}