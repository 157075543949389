.textarea {
  resize: vertical;
  min-height: 41px;
  height: 82px;
}

.input,
.textarea {
  background: transparent;
  border: none;
  border-bottom: 1px solid $beige;
  width: 100%;
  padding: 10px 0;
  font-size: 18px;
  color: $beige;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $beige;
  }
}

.input:focus,
.textarea:focus {
  &::placeholder {
    color: $beige-3;
  }
}

.field {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}