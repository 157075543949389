@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');

/* Globals */
html {
  background-color: $gray;
  background-color: rgba($gray, 0.94);
  font-size: $base-font-size;
  line-height: 24px;
  font-family: $base-font;
  font-weight: $regular;
  color: $base-color;
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

a {
  color: $beige;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

/* Headings, paragraph, link */
.h1\+ {
  font-family: $montserrat;
  font-size: 34px;
  line-height: 1.2;
  font-weight: bold;
  text-transform: none;
  color: $beige-2;
  word-break: break-word;

  @include min-width(sm) {
    font-size: 52px;
  }
}

h1,
.h1 {
  font-family: $montserrat;
  font-size: 40px;
  font-weight: bold;
  text-transform: none;
  color: $beige-2;
  word-break: break-word;
}

h2,
.h2 {
  font-family: $playfair-display;
  font-size: 24px;
  font-weight: $regular;
}

h3,
.h3 {
  font-family: $playfair-display;
  font-size: 18px;
  font-weight: $regular;
}

h4,
.h4 {
  font-family: $playfair-display;
  font-size: 18px;
  font-weight: $regular;
}

h5,
.h5 {
  font-family: $montserrat;
  font-size: 16px;
  font-weight: $regular;
  color: $beige;
  margin: 0;
}

.letter-spacing-1-2 {
  letter-spacing: 1.2;
}

.uppercase {
  text-transform: uppercase;
}

.f-montserrat {
  font-family: $montserrat;
}

.f-playfair-display {
  font-family: $playfair-display;
}

.f-tiny {
  font-size: 11px;
}

.f-smallest {
  font-size: 12px;
}

.f-small {
  font-size: 14px;
}

.f-bold {
  font-weight: $bold;
}

