.list-unstyled {
  position: relative;
  padding-left: 0;
  margin: 0;
  list-style-type: none;

  &:before {
    position: absolute;
    content: '\200B';
  }
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

// Generate utility classes for paddings and margins
$spaceamounts: (
  0,
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  55,
  60,
  70,
  80,
  90,
  100
);
$sides: (
  t: top,
  b: bottom,
  l: left,
  r: right,
  a: all,
  s: sides,
);

// Renders helper classes for properties with four sides
@mixin renderSidedClass($sidePrefix, $class, $propType, $property, $unit) {
  @if $sidePrefix == s {
    .#{$class} {
      #{$propType}-left: $unit;
      #{$propType}-right: $unit;
    }
  } @else {
    .#{$class} {
      #{$property}: $unit;
    }
  }
}

@mixin marginPaddingHelpers($breakpointNameDashed) {
  @each $propType, $propAbbrev in (margin: m, padding: p) {
    @each $sidePrefix, $side in $sides {
      @each $space in $spaceamounts {
        $sidePrefixDashed: if($side == all, '', -#{$sidePrefix});
        $class: #{$propAbbrev}#{$sidePrefixDashed}#{$breakpointNameDashed}-#{$space};
        $property: if($side == all, $propType, #{$propType}-#{$side});
        $unit: if($space == 0, $space, #{$space}px);

        @include renderSidedClass(
          $sidePrefix,
          $class,
          $propType,
          $property,
          $unit
        );
      }
    }
  }
}

// Margin-specific utils
.m-a {
  margin: auto;
}
.m-l-a {
  margin-left: auto;
}
.m-r-a {
  margin-right: auto;
}
.m-t-a {
  margin-top: auto;
}
.m-b-a {
  margin-bottom: auto;
}

.m-s-a {
  margin-right: auto;
  margin-left: auto;
}

// Create classes to break out of wrapper gutters via negative margins
@mixin negativeMarginHelpers($breakpointNameDashed) {
  @each $space in $spaceamounts {
    @if $space != 0 {
      $unit: -#{$space}px;

      @each $sidePrefix, $side in $sides {
        $sidePrefixDashed: if($side == all, '', -#{$sidePrefix});
        $class: nm#{$sidePrefixDashed}#{$breakpointNameDashed}-#{$space};
        $propType: margin;
        $property: if($side == all, $propType, #{$propType}-#{$side});

        @include renderSidedClass(
          $sidePrefix,
          $class,
          $propType,
          $property,
          $unit
        );
      }
    }
  }
}

// Create responsive helpers for all breakpoints
@each $breakpointName, $breakpointValue in $grid-breakpoints {
  $breakpointNameDashed: if($breakpointName == xs, '', -#{$breakpointName});

  @if $breakpointName == xs {
    @include marginPaddingHelpers($breakpointNameDashed);
    @include negativeMarginHelpers($breakpointNameDashed);
  } @else {
    @media (min-width: #{$breakpointValue}) {
      @include marginPaddingHelpers($breakpointNameDashed);
      @include negativeMarginHelpers($breakpointNameDashed);
    }
  }
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.text-right-sm {
  @include min-width(sm) {
    text-align: right;
  }
}

.text-center-sm {
  @include min-width(sm) {
    text-align: center;
  }
}

.opacity-47 {
  opacity: 0.47;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-no-wrap {
  white-space: nowrap;
}

.position-relative {
  position: relative;
}

.container {
  width: 100%;
  max-width: $container-width;
  padding-left: $grid-gutter * 0.5;
  padding-right: $grid-gutter * 0.5;
  position: relative;
  margin: 0 auto;
}

.container--hero {
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
}

.color-beige {
  color: $beige;
}

.color-beige-2 {
  color: $beige-2;
}

.color-beige-3 {
  color: $beige-3;
}

.color-white {
  color: $white;
}

strong,
.bold {
  font-weight: $bold;
}

.no-wrap {
  white-space: nowrap;
}

.d-flex {
  display: flex;
  flex-direction: column;
}

.h-100 {
  height: 100%;
}

.flex-grow {
  flex-grow: 1;
}

.mw-100 {
  max-width: 100%;
}

.letter-spacing-negative {
  letter-spacing: -0.7px;

  &-2x {
    letter-spacing: -1px;
  }
}

.text-shadow {
  text-shadow: 2px 5px 10px rgba(0, 0, 0, 0.7);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
