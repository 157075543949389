.section {
  background: no-repeat center center fixed;
  background-size: cover;
  position: relative;

  @include max-width('sm') {
    background-image: none !important;
  }

  &__mobile-image {
    display: none;

    @include max-width('sm') {
      display: block;
      height: 100%;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }

  &__spacing {
    overflow: hidden;
    position: relative;
    text-align: center;

    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);

    &--no-mobile {
      @include max-width('sm') {
        display: none;
      }
    }
  }

  .buroo-logo {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    @include max-width('sm') {
      width: 30vw;
    }
  }

  &__content {
    min-height: 100vh;
    opacity: 0.94;
    background-color: #222229;
    display: flex;
    align-items: center;

    @media (min-width: 800px) {
      &.is-fixed {
        position: fixed;
        width: 100vw;
        top: 0;
        left: 0;
      }

      &.hidden {
        display: none;
      }
    }
  }
}

.section__content--no-flex {
  display: block;
  opacity: 1;
}

.section--rent {
  background: #222229;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
