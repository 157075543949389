$sidenav-bg: rgba(34, 34, 41, 0.94);

.sidenav__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100vw;
  height: 100vh;
  width: rem(320px);
  padding-top: rem(120px);
  padding-right: 1rem;
}

.sidenav__nav {
  list-style-type: none;
  text-align: right;
  padding: 0;
}

.sidenav__nav-link {
  display: block;
  text-transform: uppercase;
  letter-spacing: 1.07px;
  font-size: rem(16px);
  color: $beige;
  text-decoration: none;
  margin-bottom: rem(38px);
  cursor: pointer;

  &.is-active {
    font-weight: bold;
  }
}
