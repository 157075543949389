.floor {
  background: url(../../images/building-gray-2x.jpg);
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #222229;
    opacity: 0.94;
  }
}
