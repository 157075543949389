.languages {
  display: flex;
  align-self: flex-end;
  margin-bottom: rem(60px);
  position: relative;
  padding-left: 0;
  list-style-type: none;

  &:before {
    position: absolute;
    content: '\200B';
  }
}

.languages__link {
  font-size: rem(18px);
  margin-right: rem(10px);
  padding-right: rem(10px);
  border-right: 1px solid $beige;
  letter-spacing: 1.07px;

  .languages li:last-child & {
    border-right: none;
  }

  &.is-active {
    font-weight: bold;
  }
}
