.list {
  font-size: 18px;
  letter-spacing: 1.2px;
  color: $beige;
  padding: 0;
  margin: 0;
}

.list__item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $beige;
  padding: 15px 0;
}
