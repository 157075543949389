.floor-plan {
  width: 100%;
  max-width: 100%;
  position: relative;

  svg {
    fill: none;
  }

  [id^=pind] {
    fill: transparent;
    opacity: 0.38;
    cursor: pointer;

    &.is-booked {
      fill: $beige-2;
      opacity: 0.45;
    }

    &:hover:not([disabled]) {
      fill: #598B78;
    }

    &[disabled] {
      fill: #D0536B;
      cursor: initial;
    }
  }
}

.floor-plan__floor {

  > svg {
    z-index: 5;
  }
}

.floor-plan__image {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.floor-plan__legend {
  position: absolute;
  bottom: 0;
  right: 0;
}