$light-beige: shade($beige, 61);

.table {
  width: 100%;
  overflow: auto;
}


// td {
//   cursor: pointer;
// }

.table__table {
  width: 100%;
  color: $beige;
  border-collapse: collapse;
  letter-spacing: 1.2px;

  @include min-width('sm') {
    font-size: 18px;
  }

  th {
    display: none;
  }

  td:last-child {
    border-color: $beige;
  }

  td {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: transparent;
    display: flex;
    justify-content: space-between;
    text-align: none;

    .table--no-hover & {
      cursor: default;
    }
  }

  .table__cell-label {
    min-width: 40%;
    font-weight: bold;
  }

  .table__cell-small {
    width: 200px;
  }

  td,
  th {
    padding: 12px 15px;
  }

  tr.is-disabled {
    opacity: 0.39;

    td {
      cursor: default;
    }
  }
}

@media (min-width: 768px) {
  .table__table {
    text-align: right;

    th,
    td {
      display: table-cell;
    }

    .table__cell-label {
      display: none;
    }

    td {
      border-color: $beige;
    }

    td,
    th {
      &:first-child {
        padding-left: 0;
        text-align: left;
        width: 0.00001%;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}
