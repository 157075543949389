/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color};
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color};
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

// Function for converting a px based font-size to rem.
@function rem($size) {
  $remSize: $size / $base-font-size;
  @return #{$remSize}rem;
}

@mixin min-width($breakpoint) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    $value: map-get($grid-breakpoints, $breakpoint);

    @media screen and (min-width: $value) {
      @content;
    }
  }
}

@mixin max-width($breakpoint) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    $value: map-get($grid-breakpoints, $breakpoint);

    @media screen and (max-width: $value) {
      @content;
    }
  }
}
