.room__image {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
}

.room__sidenav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100vw;
  height: 100vh;
  width: rem(500px);
  padding-top: rem(120px);
  padding-right: 1rem;
  background: rgba(34, 34, 41, 1);
}

.room__legend {
  max-width: rem(250px);
}
