.Popover-tip {
  display: none;
}

.Popover {
  z-index: 100;
}

.popover {
  color: $black;
  padding: 20px;
  padding-top: 15px;
  padding-right: 40px;

  p {
    margin: 0;
  }

  .Popover-right & {
    padding: 20px;
    padding-top: 15px;
    padding-left: 40px;
  }
}

.popover--small {
  width: 300px;
}

.popover__card {
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  position: absolute;

  .Popover-right & {
    transform: rotate(180deg);
  }
}

.popover__props {
  margin-top: 10px;
}
