.menu-button {
  padding: 1rem;
  z-index: 6;
  border: none;
  background: transparent;
  cursor: pointer;

  &.is-fixed {
    position: fixed;
    top: rem(22px);
    right: calc(50% - 40rem);

    @include max-width('lg') {
      right: 3rem;
    }
  }

  &:focus {
    outline: none;
  }

  &__image {
    width: 36px;
    padding: 6px;

    &.black {
      background: rgba(34, 34, 41, 0.96);;
    }
  }
}
