.floor-selector {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  height: 76.5vh;
  @include max-width('sm'){
    height: calc(var(--vh, 1vh) * 76.5);
  }
  min-width: 44px;

  &--fixed {
    height: 5rem;
    width: 100%;
    flex-direction: row-reverse;

    @include min-width('sm') {
      height: 50rem;
      width: 44px;
      flex-direction: column;
    }

    .floor-selector__column {
      width: 100%;
    }

    .floor-selector__floor-number {
      width: 100%;
    }
  }

  &__floor {
    position: relative;
    width: 100%;
    flex-grow: 1;
    text-align: right;
    z-index: 2;
    color: $black;
    font-weight: 300;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;

    &:hover,
    &.is-active {
      background: rgba(34, 34, 41, 0.76);
      color: $beige-2;
      &::after {
        background: rgba(34, 34, 41, 0.76);
      }
    }

    // &.floor--6::after {
    //     width: 100%;
    //     height: 100%;
    //     margin-left: -20%;
    //     background: rgba(34, 34, 41, 0.76);
    // }

    &.floor--6 {
      .is-offset & {
        width: 80%;
        margin-left: -15%;

        .floor-selector__floor-number {
          right: -43.5%;
        }
      }

      &:hover {
        color: rgba(34, 34, 41, 0.76);
      }
    }

    &.floor--1 {
      .is-offset & {
        &:hover {
          background: transparent;
        }
        &::after {
          z-index: 1;
          width: 101vw;
          height: 12.76vh;
          position: absolute;
          top: 0;
          left: calc(-50vw + 39vh);
          content: ' ';

          @include max-width('sm') {
            left: -1.2rem;
          }
        }
      }
    }
  }

  &__floor-number {
    position: absolute;
    right: 0;
    width: 44px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    font-size: 32px;
    font-weight: 300;
    z-index: 2;
  }

  &__column {
    height: 100%;
    width: 44px;
    background: $beige-2;
    position: absolute;
    right: 0;
    top: 0;
  }
}
