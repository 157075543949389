.button {
  background-color: transparent;
  background: transparent;
  display: inline-block;
  color: $white;
  border: 1px solid $white;
  text-transform: uppercase;
  font-weight: $bold;
  line-height: 1;
  padding: rem(20px) rem(50px);
  cursor: pointer;
  appearance: none;
  text-align: center;

  &:hover:not(:disabled) {
    opacity: 0.8;
    background: $beige-2;
    border-color: $black;
    color: $gray;
  }

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: initial;
  }
}

.button--block {
  width: 100%;
  display: block;
  padding-left: 0;
  padding-right: 0;
}

.button--small {
  font-size: 11px;
  padding: rem(10px) rem(25px);
}

.button--icon {
  padding: 0;
  border: none;
  background: transparent;

  &:hover {
    color: $white !important;
    background: transparent !important;
  }
}

.button--inverse {
  background: $beige-2;
  border-color: $black;
  color: $gray;
  transition: opacity 0.25s;
}
