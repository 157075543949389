/*
 * Typography
 */

// Fonts
$montserrat: 'Montserrat', sans-serif;
$playfair-display: 'Playfair Display', serif;

// Base
$base-font-size: 14px;
$base-line-height: 1.5;
$base-font: $montserrat;
$base-color: $white;

// Font weights
$regular: 300;
$bold: 700;

// THIS IS NOT USED
// Typography
$h1-size: 50px; // TODO: to rem
$h1-font: $montserrat;
$h1-font-weight: $bold;

$h2-size: 40px; // TODO: to rem
$h2-font: $playfair-display;
$h2-font-weight: $regular;

$h3-size: 32px; // TODO: to rem
$h3-font: $playfair-display;

$h4-size: 20px; // TODO: to rem
$h4-font: $playfair-display;
$h4-font-weight: $regular;