.map {
  width: 100%;
  height: 100%;
  background: no-repeat center center fixed;
  background-size: cover;
  position: relative;

  @include max-width('sm') {
    background-image: none !important;
  }

  &__mobile-image {
    display: none;

    @include max-width('sm') {
      display: block;
      height: 100%;
      margin-left: 50%;
      transform: translateX(-45%);
    }
  }


  &__container {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    background: #222229;
  }

  &__tooltip {
    position: absolute;
    bottom: rem(40px);
    right: calc(50vw - 35rem);

    @include max-width('sm') {
      right: 3rem;
    }

    font-weight: 400;
    font-size: rem(16px);
    color: $beige-2;

    a,
    a:hover {
      padding-bottom: rem(5px);
      border-bottom: 1px solid $beige-2;
      text-decoration: none;
    }
  }

}