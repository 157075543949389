.floorselect {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: no-repeat center center;
  background-size: cover;
  text-align: center;
  overflow: hidden;

  &.scale-vertically {
    background-size: contain;
  }

  &__upper-spacing {
    height: 18.75vh;
    height: calc(var(--vh, 1vh) * 18.75);
  }

  .floor-selector {
    margin-right: 3rem;
    width: 75vh;
    height: calc(var(--vh, 1vh) * 75);

    @include max-width('sm') {
      width: 80vw;
    }
  }
}