@import '~slick-carousel/slick/slick.css';

.slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 2;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  border: none;
  color: transparent;
  overflow: hidden;
  width: rem(74px);
  height: rem(74px);
  cursor: pointer;

  @include max-width('sm') {
    width: rem(24px);
    height: rem(24px);
  }

  &:focus {
    outline: none;
  }
}

.slick-prev {
  background-image: url(../../images/arrow-left.svg);
  left: calc(50% - 40rem);

  @include max-width('lg') {
    left: 3rem;
  }
}

.carousel {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  background-color: #222229;
  margin-bottom: -6px;

  &__label {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    @include max-width('sm') {
      width: 4rem;
    }
  }
}

.slick-next {
  background-image: url(../../images/arrow-right.svg);
  right: calc(50% - 40rem);

  @include max-width('lg') {
    right: 3rem;
  }
}

.slick-dots {
  position: absolute;
  bottom: 3rem;
  left: 50%;
  padding: 0;
  margin: 0;
  transform: translateX(-50%);
  list-style-type: none;
  display: inline-flex;
  justify-content: center;

  li {
    width: 0.5rem;
    height: 0.5rem;
    margin: 0 0.5rem;
    background: $gray;
    opacity: 0.26;
    display: inline-block;
    z-index: 2;

    &.slick-active {
      opacity: 1;
    }
  }

  button {
    display: none;
  }
}

.carousel__figure {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-bottom: 100vh;
}

.carousel__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
