/*
 * Grid
 */

$grid-breakpoints: (
  xs: 320px,
  sm: 800px,
  md: 1000px,
  lg: 1200px,
  xl: 1680px,
);

$grid-columns: 12;
$grid-gutter: 1rem;
$container-width: 80rem; // 1120px